import React, { useState } from 'react'
import { useMe } from '../../api/user'
import { Navigate } from 'react-router-dom'
import DealerPointPanel from './DealerPointPanel'
import ClosingDate from './ClosingDate'
import EditPrizeBanner from './EditPrizeBanner'
import IndirectDealerPanel from './IndirectDealerPanel'
import WinnerPicker from './WinnerPicker'
import { Box, Tabs, Tab } from '@mui/material'
import WinnerList from './WinnerList'

const LuckyDrawAdminPanel = () => {
  const { data } = useMe({})
  const [tabIndex, setTabIndex] = useState('point')

  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  if (!data?.luckyDraw) {
    return <Navigate to='/admin' />
  }

  const renderTabContent = () => {
    switch (tabIndex) {
      case 'point':
        return <DealerPointPanel />
      case 'closing':
        return <ClosingDate />
      case 'banner':
        return <EditPrizeBanner />
      case 'indirect':
        return <IndirectDealerPanel />
      case 'winner-picker':
        return <WinnerPicker />
      case 'winner-list':
        return <WinnerList />
      default:
        return null
    }
  }

  return (
    <Box marginTop={15} marginBottom={15}>
      <Tabs
        style={{ marginTop: 15 }}
        value={tabIndex}
        onChange={handleChange}
        aria-label='Search type'
        indicatorColor='primary'
        variant='fullWidth'
      >
        <Tab label='Ticket History' value='point' />
        {/* <Tab label='Closing Date' value='closing' /> */}
        {/* <Tab label='Prize Banner' value='banner' /> */}
        <Tab label='Indirect Dealer' value='indirect' />
        <Tab label='Winner List' value='winner-list' />
        {/* <Tab label='Winner Picker' value='winner-picker' /> */}
      </Tabs>
      <Box marginTop={5}>{renderTabContent()}</Box>
    </Box>
  )
}

export default LuckyDrawAdminPanel
