import { Typography, Box } from '@mui/material'

const Footer = () => {
  return (
    <Box
      bgcolor='#000'
      minHeight={100}
      color='#fff'
      px={10}
      display='flex'
      justifyContent={{ xs: 'center', sm: 'left' }}
      alignItems='center'
    >
      <Typography variant='h3' color='white'>
        Copyright © {new Date().getFullYear() ?? ''}. All Rights Reserved.
      </Typography>
    </Box>
  )
}

export default Footer
