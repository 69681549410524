import { Route, Routes } from 'react-router-dom'
import SignIn from './luckyDraw/SignIn'
import PhoneVerification from './luckyDraw/PhoneVerification'
import TicketInfo from './luckyDraw/TicketInfo'
import ResetPassword from './luckyDraw/ResetPasswordForm'
import LuckyDrawPrivateRoute from './luckyDraw/LuckyDrawPrivateRoute'
import LuckyDrawRedirectRoute from './luckyDraw/LuckyDrawRedirectRoute'
import LuckyDrawInstructions from './luckyDraw/LuckyDrawInstructions'
import HeaderFooter from './luckyDraw/HeaderFooter'
import WinnerList from './luckyDraw/WinnerList'

const LuckyDrawPage = () => {
  return (
    <Routes>
      <Route element={<HeaderFooter />}>
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/sign-up' element={<PhoneVerification />} />
        <Route path='/forgot-password' element={<PhoneVerification />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/*' element={<LuckyDrawRedirectRoute />} />
      </Route>
      <Route element={<LuckyDrawPrivateRoute />}>
        <Route path='/ticket' element={<TicketInfo />} />
        <Route path='/winners' element={<WinnerList />} />
      </Route>
      <Route path='/instructions' element={<LuckyDrawInstructions />} />
    </Routes>
  )
}

export default LuckyDrawPage
