import React, { useState } from 'react'
import {
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import moment from 'moment'
import { useDeleteClosingDate, usePatchClosingDate } from '../../api/closing-date'

const DeleteComponent = (props) => {
  const { row, refetch, openDelete, onClose } = props
  const { mutateAsync } = useDeleteClosingDate()
  const { enqueueSnackbar } = useSnackbar()

  const handleDelete = async () => {
    const res = await mutateAsync({ _id: row._id }).catch((e) => {
      enqueueSnackbar('Failed to delete', {
        variant: 'error',
      })
    })
    if (res?.status === 204) {
      enqueueSnackbar(`Closing Date deleted`, {
        variant: 'success',
      })
      refetch()
    }
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog maxWidth='sm' open={openDelete}>
      <DialogTitle id='edit-endDate-title'>Are you sure?</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Do you want to delete <b>{moment(row.ClosingDate).format('MMMM YYYY')}</b>? This process
          cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' onClick={handleDelete}>
          Yes, delete it
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const ClosingDateRow = (props) => {
  const { row, refetch, EditClosingDate } = props

  const [open, setOpen] = React.useState(false)
  const currentDate = row.ClosingDate
  const [date, setDate] = React.useState(currentDate?.split('T')[0])
  const [openDelete, setOpenDelete] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleCloseDeleteDialog = () => {
    setOpenDelete(false)
  }

  const handleOnClickDelete = () => {
    setOpenDelete(!openDelete)
  }

  const { mutateAsync: patch } = usePatchClosingDate()

  const handleSubmit = async () => {
    try {
      await patch({ _id: row._id, date, currentDate })
      enqueueSnackbar('Closing Date updated successfully', {
        variant: 'success',
      })
      setOpen(false)
      refetch()
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.message ?? 'Error creating Closing date', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>{moment(row.ClosingDate).format('MMMM YYYY')}</TableCell>
        <TableCell>{moment(row.ClosingDate).format('DD MMMM YYYY')}</TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(true)}>
            <Edit />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={handleOnClickDelete}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <DeleteComponent
        row={row}
        refetch={refetch}
        openDelete={openDelete}
        onClose={handleCloseDeleteDialog}
      />
      <EditClosingDate
        open={open}
        setDate={setDate}
        handleSubmit={handleSubmit}
        setOpen={setOpen}
        date={date}
        buttonLabel='Save'
        titleLabel='Edit'
      />
    </>
  )
}
