import React from 'react'
import { Typography, Box, Button } from '@mui/material'
import { ApplicationTable } from './ApplicationTable'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'

const IndirectDealerPanel = () => {
  return (
    <>
      <Typography variant='h1' marginBottom={2}>
        Indirect Dealer List
      </Typography>
      <Box display='flex' mb={3}>
        <Button
          variant='contained'
          color='primary'
          component={Link}
          to='/admin/luckydraw-create-indirect'
          startIcon={<AddIcon />}
          size='large'
        >
          CREATE
        </Button>
      </Box>
      <ApplicationTable />
    </>
  )
}

export default IndirectDealerPanel
