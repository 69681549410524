import { Box, Button } from '@mui/material'
import { TermsCondition } from './TermsCondition'
import { Link } from 'react-router-dom'
// import { useGetPrizeBanner } from '../api/banner'
import { luckyDrawBanner } from '../constant/image'
import Navbar from '../shared/Navbar'
const LuckyDrawInstructions = () => {
  // const { data, isLoading } = useGetPrizeBanner()
  return (
    <Box>
      <Navbar />
      {/* <Box display='flex' justifyContent='center' marginTop={7}>
        {!isLoading && <img src={`${data[0]?.Banner}`} width='100%' />}
      </Box> */}
      <Box display='flex' justifyContent='center' marginTop={7}>
        <img src={luckyDrawBanner} width='100%' />
      </Box>
      <TermsCondition />
      <Box display='flex' justifyContent='center' alignItems='center' mb={10}>
        <Button
          color='primary'
          variant='contained'
          sx={{ minWidth: '250px' }}
          component={Link}
          to='/luckydraw/sign-in'
        >
          Join the promotion
        </Button>
      </Box>
    </Box>
  )
}

export default LuckyDrawInstructions
