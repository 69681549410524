import React, { useState, useEffect, useCallback } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
  TablePagination,
  TableFooter,
  IconButton,
  Skeleton,
} from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { useTheme } from '@mui/material/styles'
import { debounce } from '@mui/material'
import { ApplicationRow } from './ApplicationRow'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetIndirectDealers } from '../../api/application'

export const ApplicationTable = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const pageParam = Number(searchParams.get('page'))
  const limitParam = Number(searchParams.get('limit'))
  useEffect(() => {
    if (Number.isInteger(pageParam)) {
      setPage(pageParam)
    }
    if (limitParam) {
      setLimit(limitParam)
    }
  }, [limitParam, pageParam])

  const [page, setPage] = useState(Number.isInteger(pageParam) ? pageParam : 0)
  const [limit, setLimit] = useState(limitParam ? limitParam : 10)

  const { data, isLoading, refetch } = useGetIndirectDealers({
    page: page + 1,
    limit: limit,
  })

  const applicationData = data?.docs ?? []

  const debounceRefetch = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    [refetch]
  )
  useEffect(() => {
    debounceRefetch()
    window.scrollTo(0, 0)
  }, [limit, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    navigate(`?page=${newPage}&limit=${limit}`)
  }

  const handleChangeRowsPerPage = (event) => {
    if (limit) {
      setLimit(parseInt(event.target.value, 10))
      setPage(0)
      navigate(`?page=0&limit=${event.target.value}`)
    }
  }

  return (
    <>
      {(applicationData.length > 0 || isLoading) && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Business Name</TableCell>
                <TableCell>Business Owner Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Allocated Tickets</TableCell>
                <TableCell>Reseller Name</TableCell>
                <TableCell>Period</TableCell>
                <TableCell style={{ textAlign: 'right' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? Array(10)
                    .fill()
                    .map((_, idx) => <SkeletonRow key={idx} height={30} />)
                : [...(applicationData ?? [])].map((row, idx) => (
                    <ApplicationRow key={idx} row={row} refetch={refetch} />
                  ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  colSpan={10}
                  count={data?.totalDocs ?? 0}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

const SkeletonRow = React.memo(({ height }) => (
  <TableRow>
    <TableCell colSpan='100%' height={height}>
      <Skeleton width='100%' height='100%' />
    </TableCell>
  </TableRow>
))

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}
