import React from 'react'
import { Box, Stepper, Step, StepLabel, StepContent, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

const TermsCondition = () => {
  const steps = [
    {
      label: 'Step 3',
      description: `Fill in the form and attach a digital copy of your proof of purchase. The battery serial number must be provided to ensure it tallies in HT Eco Battery Sdn Bhd system.`,
    },
    {
      label: 'Step 4',
      description: `Submit the completed form. By submitting this form, you confirm that you have read, understood, and will respect the terms and conditions & PDPA.`,
    },
    {
      label: 'Step 5',
      description: `You will receive Touch'N Go reload pin number within 3 working days if your submission is approved.`,
    },
  ]

  return (
    <>
      <Box
        minHeight={350}
        maxWidth={900}
        px={3}
        py={5}
        display='flex'
        justifyContent='center'
        alignContent='center'
        m='auto'
        flexDirection='column'
      >
        <Stepper orientation='vertical'>
          <Step active={true}>
            <StepLabel>Step 1</StepLabel>
            <StepContent>
              <Typography variant='h3'>
                Purchase any Energizer or VARTA® automotive batteries from participating dealers
                during the campaign period from 1st May 2024 to 31st December2024, with a monthly
                redemption limit of 2000.
              </Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel>Step 2</StepLabel>
            <StepContent>
              <Typography variant='h3'>
                Scan the QR code or visit{' '}
                <a href='/campaign/register'>https://htecobattery.com.my/campaign/register</a> to
                access the entry page.
              </Typography>
            </StepContent>
          </Step>
          {steps.map((step, index) => (
            <Step key={step.label} active={true}>
              <StepLabel
                optional={index === 2 ? <Typography variant='caption'>Last step</Typography> : null}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography variant='h3'>{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Box mt={3}>
          <Typography variant='h4'>
            I have read and agree with the{' '}
            <a
              style={{ color: '#002984' }}
              href={'/assets/TermsAndConditions.pdf'}
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms & Conditions
            </a>{' '}
            and{' '}
            <a
              style={{ color: '#002984' }}
              href={'/assets/PrivacyPolicy.pdf'}
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy.
            </a>
          </Typography>
        </Box>
      </Box>
      <Box display='flex' justifyContent='center' alignItems='center' mb={10}>
        <Button
          color='primary'
          variant='contained'
          sx={{ minWidth: '250px' }}
          component={Link}
          to='/campaign/register'
        >
          Join the promotion
        </Button>
      </Box>
    </>
  )
}

export default TermsCondition
