import { useQuery, useMutation } from 'react-query'
import axios from 'axios'
import { serializeQuery } from './application'

const ENDPOINT = '/api/winner'
const QUERY_KEY = 'winner'

export const useGetWinner = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await axios.get(serializeQuery(ENDPOINT, query))).data,
    config
  )

export const usePostWinner = (config = {}) =>
  useMutation((data) => axios.post(ENDPOINT, data), config)

export const useExportWinner = (config = {}) =>
  useMutation(
    (data) =>
      axios.get(`${ENDPOINT}/export-winner`, {
        params: data,
        responseType: 'blob',
      }),
    config
  )
