import React from 'react'
import {
  Box,
  Paper,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material'
import { ClosingDateRow } from './ClosingDateRow'
import { useSnackbar } from 'notistack'
import AddIcon from '@mui/icons-material/Add'
import { useGetClosingDate, usePostClosingDate } from '../../api/closing-date'

const EditClosingDate = (props) => {
  const { open, setDate, handleSubmit, setOpen, date, buttonLabel, titleLabel } = props

  return (
    <Dialog maxWidth='sm' open={open} fullWidth>
      <DialogTitle id='edit-endDate-title'>{titleLabel} Closing Date</DialogTitle>
      <DialogContent dividers>
        <TextField
          id='date'
          label='Closing Date'
          type='date'
          defaultValue={date}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          variant='outlined'
          helperText='The month will be automatically selected based on the selected date.'
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)}>
          CANCEL
        </Button>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ClosingDate = () => {
  const { data = {}, isLoading, refetch } = useGetClosingDate()
  const dataClosingDate = data || []
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const currentDate = new Date()
  const [date, setDate] = React.useState(currentDate.toISOString().slice(0, 10))

  const { mutateAsync: post } = usePostClosingDate()

  const handleSubmit = async () => {
    try {
      await post({ date })
      enqueueSnackbar('Closing Date created successfully', {
        variant: 'success',
      })
      setOpen(false)
      refetch()
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.message ?? 'Error creating Closing date', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Typography variant='h1' marginBottom={2}>
        Closing Date
      </Typography>
      <Box display='flex' mb={3}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpen(true)}
          startIcon={<AddIcon />}
          size='large'
        >
          CREATE
        </Button>
      </Box>
      <EditClosingDate
        open={open}
        setDate={setDate}
        handleSubmit={handleSubmit}
        setOpen={setOpen}
        date={date}
        buttonLabel='Create'
        titleLabel='Create'
      />
      <TableContainer component={Paper}>
        <Table aria-label='order table'>
          {!isLoading && (
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: 'left' }}>Month</TableCell>
                <TableCell style={{ textAlign: 'left' }}>Closing Date</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {!isLoading &&
              dataClosingDate?.map?.((row) => (
                <ClosingDateRow
                  row={row}
                  key={row._id}
                  refetch={refetch}
                  EditClosingDate={EditClosingDate}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ClosingDate
