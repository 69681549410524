import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { Typography, Skeleton, Button, Grid, Box } from '@mui/material'
import { useGetTickets } from '../../api/point'
import { useSnackbar } from 'notistack'

const WinnerPicker = ({ queryPeriod }) => {
  const { data: pointData = [], isLoading } = useGetTickets(
    { month: queryPeriod[0], year: queryPeriod[1] },
    { staleTime: 1000 * 60 * 24 }
  )

  const [filteredCompany, setFilteredCompany] = useState(pointData)

  const [name, setName] = useState('Winner Picker')
  const [winners, setWinners] = useState({})

  const { enqueueSnackbar } = useSnackbar()

  const [isRendering, setIsRendering] = useState(false)

  useEffect(() => {
    if (isRendering) {
      const i = setInterval(() => {
        setName(filteredCompany[Math.floor(Math.random() * filteredCompany.length)])
      }, 30)
      return () => clearInterval(i)
    }
  }, [isRendering, filteredCompany])

  useEffect(() => {
    if (!isRendering) {
      const newWinners = { ...winners, [name]: (winners[name] || 0) + 1 }
      if (newWinners[name] === 3) {
        setFilteredCompany((company) => company.filter((user) => user !== name))
      } else {
        setFilteredCompany((currentCompany) => {
          const updatedCompany = [...currentCompany]
          const indexToRemove = updatedCompany.indexOf(name)
          if (indexToRemove !== -1) updatedCompany.splice(indexToRemove, 1)
          return updatedCompany
        })
      }
      setWinners(newWinners)
    }
  }, [name, isRendering])

  useEffect(() => {
    if (pointData && !isLoading) {
      setFilteredCompany(pointData)
    }
  }, [pointData, isLoading])

  const handleTypographyClick = () => {
    navigator.clipboard
      .writeText(name)
      .then(() => {
        enqueueSnackbar(`Successfully copied`, {
          variant: 'success',
        })
      })
      .catch((err) => {
        enqueueSnackbar(`Failed to copy`, {
          variant: 'error',
        })
      })
  }

  return (
    <>
      <Confetti height={'2000px'} />
      <Grid container alignItems='center' marginTop={5}>
        <Box
          flex={1}
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ border: '2px solid black', cursor: 'pointer' }}
          onClick={handleTypographyClick}
        >
          <Typography
            sx={{
              fontSize: '30px',
              textTransform: 'uppercase',
              transition: '0.5s all',
            }}
          >
            {isLoading ? <Skeleton style={{ width: '100%' }} /> : name}
          </Typography>
        </Box>
        {!isLoading && (
          <Button
            variant='contained'
            onClick={() => setIsRendering((prevState) => !prevState)}
            sx={{
              backgroundColor: isRendering ? 'red' : 'green',
              '&:hover': {
                backgroundColor: isRendering ? 'red' : 'green',
              },
              height: '48px',
            }}
          >
            {isRendering ? 'STOP' : 'START'}
          </Button>
        )}
      </Grid>
    </>
  )
}

export default WinnerPicker
