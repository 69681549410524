import { useQuery, useMutation } from 'react-query'
import axios from 'axios'
import { serializeQuery } from './application'

const ENDPOINT = `/api/point`
const QUERY_KEY = 'point'

export const useGetPoint = (query = {}, config = {}) =>
  useQuery(
    [`${QUERY_KEY}_single`, 'list', query],
    async () => (await axios.get(serializeQuery(`${ENDPOINT}/point`, query))).data,
    config
  )

export const useGetPoints = (query = {}, config = {}) =>
  useQuery(
    [`${QUERY_KEY}_multiple`, 'list', query],
    async () => (await axios.get(serializeQuery(`${ENDPOINT}/points`, query))).data,
    config
  )

export const useGetTickets = (query = {}, config = {}) =>
  useQuery(
    [`${QUERY_KEY}_tikcet`, 'list', query],
    async () => (await axios.get(serializeQuery(`${ENDPOINT}/tickets`, query))).data,
    config
  )

export const useExportReseller = (config = {}) =>
  useMutation(
    (data) =>
      axios.get(`${ENDPOINT}/export-reseller`, {
        params: data,
        responseType: 'blob',
      }),
    config
  )

export const useExportDealer = (config = {}) =>
  useMutation(
    (data) =>
      axios.get(`${ENDPOINT}/export-dealer`, {
        params: data,
        responseType: 'blob',
      }),
    config
  )

export const useExportTicket = (config = {}) =>
  useMutation(
    (data) =>
      axios.get(`${ENDPOINT}/export-ticket`, {
        params: data,
        responseType: 'blob',
      }),
    config
  )
