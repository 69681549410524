export const state = [
  'Johor',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Kuala Lumpur',
  'Selangor',
  'Putrajaya',
  'Kelantan',
  'Terengganu',
  'Perak',
  'Kedah',
  'Perlis',
  'Pulau Pinang',
  'Sarawak',
  'Sabah',
  'Labuan',
]
