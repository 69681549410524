import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Grid,
  TextField,
  FormHelperText,
  Button,
  CircularProgress,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import { usePostIndirectDealer } from '../api/application'
import { useSnackbar } from 'notistack'

export const IndirectDealerForm = ({ setOpen, refetch, maxTicket }) => {
  const [uploadError, setUploadError] = React.useState('')
  const { register, handleSubmit, control, setValue, reset } = useForm({
    mode: 'onChange',
  })
  const { mutateAsync, isLoading } = usePostIndirectDealer()
  const { enqueueSnackbar } = useSnackbar()

  const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false)
  const [formValues, setFormValues] = React.useState({})

  const onSubmit = async (values) => {
    setIsConfirmationOpen(true)
    setFormValues(values)
  }

  const onConfirmSubmit = async () => {
    let formData = new FormData()
    for (let key in formValues) {
      if (['transProof'].includes(key)) {
        formData.append(key, formValues[key][0])
      } else {
        formData.append(key, formValues[key])
      }
    }
    try {
      await mutateAsync(formData)
      enqueueSnackbar('Indirect dealer details submitted successfully', {
        variant: 'success',
      })
      refetch()
      setIsConfirmationOpen(false)
      reset()
      setFormValues({})
      setOpen(false)
    } catch (error) {
      enqueueSnackbar('Error submitting indirect dealer details', {
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Button
        variant='contained'
        sx={{
          borderRadius: '50px',
          pointerEvents: 'none',
          marginBottom: 3,
        }}
      >
        Indirect POS Details
      </Button>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            name='companyName'
            control={control}
            defaultValue=''
            rules={{
              required: 'Business name is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Indirect POS’s Business Name'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='ownerName'
            control={control}
            defaultValue=''
            rules={{
              required: 'Owner name is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Business Owner Name'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='phone'
            control={control}
            defaultValue=''
            rules={{
              required: 'Contact number is required',
              pattern: {
                value: /^(601)[0-46-9]-*[0-9]{7,8}$/,
                message: `Invalid phone number. Phone number should follow the format of 601xxxxxxxx. '+' sign is not required.`,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Contact Number'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='businessType'
            control={control}
            rules={{
              required: 'Business type is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={['Battery Shop', 'Workshop', 'Tyre Shop', 'Trader']}
                onChange={(e, data) => onChange(data)}
                value={value || ''}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    error={!!error}
                    fullWidth
                    helperText={error ? error.message : null}
                    {...params}
                    label='Business Type'
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='address1'
            control={control}
            defaultValue=''
            rules={{
              required: 'Delivery address is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Delivery Address (Street Address Line 1)'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='address2'
            control={control}
            defaultValue=''
            rules={{
              required: 'Delivery address is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Delivery Address (Street Address Line 2)'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name='postcode'
            control={control}
            defaultValue=''
            rules={{
              required: 'Postal code is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Postal Code'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name='city'
            control={control}
            defaultValue=''
            rules={{
              required: 'City is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='City'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name='state'
            control={control}
            defaultValue=''
            rules={{
              required: 'State is required',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='State'
                fullWidth
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name='engQuantity'
            control={control}
            defaultValue=''
            rules={{
              required: 'Number of purchase for energizer is required',
              min: { value: 0, message: 'Must be positive number' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Number of Purchase for Energizer'
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                fullWidth
                helperText={error ? error.message : null}
                type='number'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name='varQuantity'
            control={control}
            defaultValue=''
            rules={{
              required: 'Number of purchase for varta is required',
              min: { value: 0, message: 'Must be positive number' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Number of Purchase for Varta'
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                fullWidth
                helperText={error ? error.message : null}
                type='number'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name='totalTicket'
            control={control}
            defaultValue=''
            rules={{
              required: 'Allocated Number of Tickets is required',
              min: { value: 1, message: 'Must be more than 0' },
              max: {
                value: maxTicket,
                message: `Exceeding the limit for allocatable tickets of ${maxTicket} tickets`,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Allocated Number of Tickets'
                variant='outlined'
                value={value}
                onChange={onChange}
                error={!!error}
                fullWidth
                helperText={error ? error.message : null}
                type='number'
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='transProof'
            control={control}
            defaultValue=''
            rules={{
              required: 'Required',
            }}
            render={({ field: { value }, fieldState: { error } }) => {
              const props = register('transProof')
              const combinedOnchange = (e) => {
                const fileSize = e?.target?.files?.[0]?.size
                const fileMb = fileSize / 1024 ** 2
                if (fileMb > 5) {
                  setUploadError('File size must be within 5MB.')
                  setValue('transProof', null)
                } else {
                  props.onChange(e)
                  setUploadError('')
                }
              }
              return (
                <>
                  <Button
                    variant='outlined'
                    startIcon={<UploadIcon />}
                    component='label'
                    fullWidth
                    style={{ borderColor: error || uploadError ? 'red' : '' }}
                  >
                    Upload a Proof of Transactions of The Month (unit price can be covered)
                    <input
                      type='file'
                      accept='image/*'
                      hidden
                      {...props}
                      onChange={combinedOnchange}
                    />
                  </Button>
                  {error ? <FormHelperText error={true}>{error?.message}</FormHelperText> : null}
                  {value ? <FormHelperText>{value[0]?.name}</FormHelperText> : null}
                  {uploadError ? <FormHelperText error={true}>{uploadError}</FormHelperText> : null}
                </>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid container display={'flex'} justifyContent={'right'} marginTop={3}>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
          }}
          sx={{ marginRight: 2 }}
        >
          Cancel
        </Button>
        <Button type='submit' variant='contained'>
          Submit
        </Button>
      </Grid>
      <Dialog maxWidth='sm' fullWidth open={isConfirmationOpen}>
        <DialogTitle id='edit-endDate-title'>Confirm Submission</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Are you sure you want to allocate <b>{formValues.totalTicket}</b> tickets to{' '}
            <b>{formValues.companyName}</b>? This process cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => setIsConfirmationOpen(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={onConfirmSubmit}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size='1.5rem' style={{ color: 'white' }} />}
          >
            Yes, submit
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
