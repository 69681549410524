import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Navbar from '../shared/Navbar'
import Footer from '../shared/Footer'
import { tngBanner } from '../constant/image'

const HeaderFooter = () => {
  return (
    <Box>
      <Navbar />
      <Box display='flex' justifyContent='center' marginTop={7}>
        <img src={tngBanner} width='100%' />
      </Box>
      <Outlet />
      <Footer />
    </Box>
  )
}

export default HeaderFooter
