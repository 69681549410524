import React from 'react'
import { TextField } from 'mui-rff'
import { Button, Box } from '@mui/material'
import { Form } from 'react-final-form'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useResetPassword } from '../api/user'

const ResetPassword = () => {
  const { mutateAsync } = useResetPassword()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async (values) => {
    const res = await mutateAsync({ ...values }).catch((e) => {
      enqueueSnackbar(e?.response?.data?.message ?? 'Error', {
        variant: 'error',
      })
    })
    if (res?.status === 200) {
      enqueueSnackbar('Password has been reset.', {
        variant: 'success',
      })
    }
    navigate('/luckydraw/sign-in')
  }

  const validate = (values) => {
    const errors = {}

    if (!values.newPassword) {
      errors.newPassword = 'Required'
    } else if (values.newPassword.length < 6) {
      errors.newPassword = 'Password must atleast have 6 character'
    }

    if (!values.verifyNewPassword) {
      errors.verifyNewPassword = 'Required'
    } else if (values.verifyNewPassword.length < 6) {
      errors.verifyNewPassword = 'Password must atleast have 6 character'
    } else if (values.newPassword !== values.verifyNewPassword) {
      errors.verifyNewPassword = 'Password must match'
    }

    return errors
  }

  const formFields = [
    {
      size: 12,
      field: (
        <TextField
          type='password'
          label='New Password'
          name='newPassword'
          margin='none'
          required={true}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          type='password'
          label='Verify New Password'
          name='verifyNewPassword'
          margin='none'
          required={true}
        />
      ),
    },
  ]
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            minHeight={180}
            marginTop={15}
          >
            {formFields.map((item, idx) => (
              <Box xs={item.size} key={idx} display='flex' flexDirection='column'>
                {item.field}
              </Box>
            ))}
            <Button
              variant='contained'
              type='submit'
              disabled={submitting}
              style={{ backgroundColor: 'black', color: 'white' }}
            >
              Confirm
            </Button>
          </Box>
        </form>
      )}
    />
  )
}

export default ResetPassword
