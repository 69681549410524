import { Routes, Route } from 'react-router-dom'
import Login from './Admin/Login'
import AdminRedirectRoute from './Admin/AdminRedirectRoute'
import AdminPrivateRoute from './Admin/AdminPrivateRoute'
import LuckyDrawAdminPanel from './Admin/LuckyDraw/LuckyDrawAdminPanel'
import ApplicationTable from './Admin/TouchNGo/ApplicationTable'
import Header from './Admin/Header'
import ReviewApplication from './Admin/TouchNGo/ReviewApplication'
import IndirectDealerForm from './Admin/LuckyDraw/IndirectDealerForm'

const AdminPage = () => {
  return (
    <Routes>
      <Route element={<AdminPrivateRoute />}>
        <Route path='/panel' element={<ApplicationTable />} />
        <Route path='/review-application' element={<ReviewApplication />} />
        <Route path='/luckydraw' element={<LuckyDrawAdminPanel />} />
        <Route path='/luckydraw-create-indirect' element={<IndirectDealerForm />} />
      </Route>
      <Route element={<Header />}>
        <Route path='/login' element={<Login />} />
        <Route path='/*' element={<AdminRedirectRoute />} />
      </Route>
    </Routes>
  )
}

export default AdminPage
