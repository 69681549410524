import React, { useState, useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  IconButton,
  Collapse,
  Box,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { usePatchIndirectDealer } from '../../api/application'
import { DisplayImageDialog } from '../../shared/DisplayImageDialog'

const Collapsible = ({ row, open }) => {
  const [openInvoice, setOpenInvoice] = useState(false)
  return (
    <TableRow style={{ border: 'none' }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <Grid container m={2} spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h5' fontWeight='bold'>
                Period: {row?.periodStart}
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                Reseller Name: {row?.resellerName} ({row?.resellerId})
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Business name</Typography>
              <Typography variant='h6'>{row?.companyName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Owner name</Typography>
              <Typography variant='h6'>{row?.ownerName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Contact number</Typography>
              <Typography variant='h6'>{row?.phone}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Business type</Typography>
              <Typography variant='h6'>{row?.businessType}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Address Line 1</Typography>
              <Typography variant='h6'>{row?.address1}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Address Line 2</Typography>
              <Typography variant='h6'>{row?.address2}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Postal code</Typography>
              <Typography variant='h6'>{row?.postcode}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>City</Typography>
              <Typography variant='h6'>{row?.city}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>State</Typography>
              <Typography variant='h6'>{row?.state}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Number of Purchase for Energizer</Typography>
              <Typography variant='h6'>{row?.engQuantity}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Number of Purchase for Varta</Typography>
              <Typography variant='h6'>{row?.varQuantity}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='h4'>Allocated Number of Tickets</Typography>
              <Typography variant='h6'>{row?.totalTicket}</Typography>
            </Grid>
            <Grid item xs={12} alignItems='center'>
              <Button onClick={() => setOpenInvoice(true)} size='small' variant='contained'>
                show proof of transactions
              </Button>
            </Grid>
          </Grid>
          <DisplayImageDialog
            handleClose={() => setOpenInvoice(false)}
            open={openInvoice}
            uploadPath={row?.transProof?.uploadPath}
          />
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

const UpdateStatusSelect = ({ row, refetch }) => {
  const [status, setStatus] = useState(row.status)
  useEffect(() => {
    setStatus(row.status)
  }, [row])

  const { enqueueSnackbar } = useSnackbar()
  const { mutateAsync: patch, isLoading } = usePatchIndirectDealer()

  const handleUpdate = async () => {
    try {
      await patch({ _id: row._id, status })
      enqueueSnackbar(`Update success`, {
        variant: 'success',
      })
      refetch()
    } catch (error) {
      enqueueSnackbar('Update fail', {
        variant: 'error',
      })
    }
  }

  return (
    <Box display='flex' flexDirection='row' justifyContent='flex-end' alignContent='center'>
      <FormControl variant='standard' sx={{ minWidth: 100 }}>
        <Select value={status} onChange={(e) => setStatus(e.target.value)}>
          <MenuItem value='Approved'>Approved</MenuItem>
          <MenuItem value='Pending'>Pending</MenuItem>
          <MenuItem value='Rejected'>Rejected</MenuItem>
        </Select>
        <FormHelperText>Update the status</FormHelperText>
      </FormControl>
      <Box ml={3}>
        <Button
          variant='contained'
          style={{ maxHeight: '30px' }}
          onClick={handleUpdate}
          disabled={status === row.status || isLoading}
          endIcon={isLoading && <CircularProgress size='1rem' />}
        >
          Update
        </Button>
      </Box>
    </Box>
  )
}

export const ApplicationRow = ({ row, refetch }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow style={{ border: 'none' }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.companyName}</TableCell>
        <TableCell>{row.ownerName}</TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell>{row.totalTicket}</TableCell>
        <TableCell>{row.resellerName}</TableCell>
        <TableCell>{row.periodStart}</TableCell>
        <TableCell>
          <UpdateStatusSelect row={row} refetch={refetch} />
        </TableCell>
      </TableRow>
      <Collapsible row={row} open={open} />
    </>
  )
}
