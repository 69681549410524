import React from 'react'
import { TextField } from 'mui-rff'
import { Typography, Button, Box } from '@mui/material'
import { Form } from 'react-final-form'
import { useSnackbar } from 'notistack'
import { useNavigate, Navigate } from 'react-router-dom'
import { useLogin, useMe } from '../api/user'

const Login = () => {
  const loginApi = useLogin()
  const { data, refetch } = useMe()
  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()
  const onSubmit = async (values) => {
    const res = await loginApi.mutateAsync(values).catch((e) => {
      enqueueSnackbar(e?.response?.data?.message ?? 'Failed to login', {
        variant: 'error',
      })
    })

    if (res?.status === 200) {
      enqueueSnackbar('Login Success', {
        variant: 'success',
      })
      await refetch()

      navigate('/admin/panel')
    }
  }

  const formFields = [
    {
      size: 12,
      field: <TextField type='email' label='Email' name='email' margin='none' required={true} />,
    },
    {
      size: 12,
      field: (
        <TextField type='password' label='Password' name='password' margin='none' required={true} />
      ),
    },
  ]

  if (data) return <Navigate to='/admin/panel' />

  return (
    <Box
      boxSizing='border-box'
      m='auto'
      width='100%'
      maxWidth={500}
      mt={10}
      mb={10}
      border='1px solid #808080'
      p={{ xs: '30px', md: '65px' }}
      borderRadius={4}
      bgcolor='#fff'
    >
      <Box mb={3}>
        <Typography variant='h5'>Login</Typography>
      </Box>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              minHeight={200}
            >
              {formFields.map((item, idx) => (
                <Box xs={item.size} key={idx} display='flex' flexDirection='column'>
                  {item.field}
                </Box>
              ))}

              <Button
                variant='contained'
                type='submit'
                disabled={submitting}
                style={{ backgroundColor: 'black', color: 'white' }}
              >
                Submit
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  )
}

export default Login
