import { Outlet } from 'react-router-dom'
import Navbar from '../shared/Navbar'
import Container from '@mui/material/Container'
import { Box } from '@mui/material'

const HeaderFooter = () => {
  return (
    <Container component='main' maxWidth='xs'>
      <Navbar />
      <Box marginTop={20}>
        <Outlet />
      </Box>
    </Container>
  )
}

export default HeaderFooter
