import React, { useEffect, useState, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  Skeleton,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import Fuse from 'fuse.js'
import { useSnackbar } from 'notistack'
import DownloadIcon from '@mui/icons-material/Download'
import { useGetPoints, useExportReseller, useExportDealer, useExportTicket } from '../../api/point'

const PointTable = ({ searchFilter, monthYearSelected }) => {
  const queryPeriod = monthYearSelected.split('/')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const exportResellerApi = useExportReseller()
  const exportDealerApi = useExportDealer()
  const exportTicket = useExportTicket()

  const batteryPoint = useGetPoints({ month: queryPeriod[0], year: queryPeriod[1] })
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    setPage(0)
  }, [searchFilter])

  const pointData = useMemo(() => {
    const list = batteryPoint?.data ?? []

    if (!searchFilter) return list.sort((a, b) => b.total - a.total)

    const itemFuse = new Fuse(list, {
      shouldSort: true,
      includeScore: true,
      threshold: 0.3,
      ignoreLocation: true,
      keys: ['companyName', 'companyId', 'phone'],
    })

    const filtereditemFuse = itemFuse.search(searchFilter)

    return filtereditemFuse
      .filter((fuse) => fuse.score < 0.5)
      .map((each) => each.item)
      .sort((a, b) => b.total - a.total)
  }, [searchFilter, batteryPoint?.data])

  const arr = useMemo(() => {
    return rowsPerPage > 0
      ? [...pointData].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : pointData
  }, [page, rowsPerPage, pointData])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleExportReseller = async () => {
    const res = await exportResellerApi
      .mutateAsync({ month: queryPeriod[0], year: queryPeriod[1] })
      .catch((e) => {
        enqueueSnackbar('Failed to export reseller data', {
          variant: 'error',
        })
      })

    if (res?.status === 200) {
      const url = URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `reseller.csv`)
      document.body.appendChild(link)
      link.click()
      enqueueSnackbar(`Reseller data exported`, {
        variant: 'success',
      })
    }
  }

  const handleExportDealer = async () => {
    const res = await exportDealerApi
      .mutateAsync({ month: queryPeriod[0], year: queryPeriod[1] })
      .catch((e) => {
        enqueueSnackbar('Failed to export dealer data', {
          variant: 'error',
        })
      })

    if (res?.status === 200) {
      const url = URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `dealer.csv`)
      document.body.appendChild(link)
      link.click()
      enqueueSnackbar(`Dealer data exported`, {
        variant: 'success',
      })
    }

    return res
  }

  const handleExportTicket = async () => {
    const res = await exportTicket
      .mutateAsync({ month: queryPeriod[0], year: queryPeriod[1] })
      .catch((e) => {
        enqueueSnackbar('Failed to export ticket', {
          variant: 'error',
        })
      })

    if (res?.status === 200) {
      const url = URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `TicketHistory.csv`)
      document.body.appendChild(link)
      link.click()
      enqueueSnackbar(`Dealer data exported`, {
        variant: 'success',
      })
    }
  }
  return (
    <Box>
      <Typography variant='h2' marginTop={5}></Typography>
      <Box display='flex' justifyContent='flex-end'>
        <Button
          startIcon={<DownloadIcon />}
          endIcon={exportResellerApi.isLoading && <CircularProgress size='1rem' />}
          onClick={handleExportReseller}
          disabled={exportResellerApi.isLoading}
        >
          Export Reseller Data
        </Button>
        <Button
          startIcon={<DownloadIcon />}
          endIcon={exportDealerApi.isLoading && <CircularProgress size='1rem' />}
          onClick={handleExportDealer}
          disabled={exportDealerApi.isLoading}
        >
          Export Dealer Data
        </Button>
        <Button
          startIcon={<DownloadIcon />}
          endIcon={exportTicket.isLoading && <CircularProgress size='1rem' />}
          onClick={handleExportTicket}
          disabled={exportTicket.isLoading}
        >
          Export Ticket
        </Button>
      </Box>
      <Table sx={{ minWidth: 500 }} aria-label='custom pagination table'>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Varta</TableCell>
            <TableCell>Energizer</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Dealer Ticket</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Reseller Ticket</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Indirect Ticket</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Allocated Ticket</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {batteryPoint.isLoading
            ? [...Array(10)].map((x, _) => (
                <TableRow key={_}>
                  {[...Array(9)].map((_, idx) => (
                    <TableCell key={idx}>
                      <Skeleton style={{ width: '100%', marginBottom: 8 }} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : arr.map((row) => (
                <TableRow
                  key={row?.companyId}
                  style={{ backgroundColor: row?.isReseller ? '#F7B82A' : '' }}
                >
                  <TableCell>{row.companyId}</TableCell>
                  <TableCell>{row.companyName}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.VTA}</TableCell>
                  <TableCell>{row.ENG}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{row.dealerTicket}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{row.resellerTicket}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{row.indirectTicket}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>{row.allocatedTicket}</TableCell>
                </TableRow>
              ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
              colSpan={9}
              count={pointData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  )
}

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

export default PointTable
