import React from 'react'
import { Dialog } from '@mui/material'

export const ImageDialog = (props) => {
  const { setOpen, imgSrc, open } = props

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      fullWidth
    >
      <img src={imgSrc} style={{ maxWidth: '100%' }} />
    </Dialog>
  )
}
