import { Routes, Route } from 'react-router-dom'
import HeaderFooter from './TouchNGo/HeaderFooter'
import ApplicationForm from './TouchNGo/ApplicationForm'
import TermsCondition from './TouchNGo/TermsCondition'

const TouchNGoPage = () => {
  return (
    <Routes>
      <Route element={<HeaderFooter />}>
        <Route path='/register' element={<ApplicationForm />} />
        <Route path='/*' element={<TermsCondition />} />
      </Route>
    </Routes>
  )
}

export default TouchNGoPage
