import React from 'react'
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Grid,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import UploadIcon from '@mui/icons-material/Upload'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import { useGetPrizeBanner } from '../../api/banner'

const EditPrizeBanner = () => {
  const { data, isLoading, refetch } = useGetPrizeBanner()
  const { register, handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
  })
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [uploadError, setUploadError] = React.useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    const banner = data?.banner?.[0]

    const formData = new FormData()

    formData.append('banner', banner, banner?.name ?? '')

    try {
      await axios({
        url: `/api/upload/banner`,
        method: 'POST',
        data: formData,
      })
      refetch()
      enqueueSnackbar('Uploaded successfully', {
        variant: 'success',
      })
      setOpen(false)
      setValue('banner', null)
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message ?? 'Upload Fail', {
        variant: 'error',
      })
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <Typography variant='h1' marginBottom={2}>
        Prize Banner
      </Typography>
      <Box display='flex' mb={3}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpen(true)}
          startIcon={<UploadIcon />}
          size='large'
        >
          UPLOAD
        </Button>
      </Box>
      {!isLoading && (
        <Grid
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          container
          padding={1}
          border={1}
        >
          <img src={`${data[0]?.Banner}`} style={{ maxWidth: '100%', height: 'auto' }} />
        </Grid>
      )}
      <Dialog maxWidth='sm' open={open} fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id='edit-endDate-title'>Upload Prize Banner</DialogTitle>
          <DialogContent dividers>
            <Box>
              <Controller
                name='banner'
                control={control}
                defaultValue=''
                rules={{
                  required: 'Required',
                }}
                render={({ field: { value }, fieldState: { error } }) => {
                  const props = register('banner')
                  const combinedOnchange = (e) => {
                    const fileSize = e?.target?.files?.[0]?.size
                    const fileMb = fileSize / 1024 ** 2
                    if (fileMb > 5) {
                      setUploadError('File size must be within 5MB.')
                      setValue('banner', null)
                    } else {
                      props.onChange(e)
                      setUploadError('')
                    }
                  }
                  return (
                    <>
                      <Button variant='outlined' startIcon={<UploadIcon />} component='label'>
                        Upload Prize Banner
                        <input
                          type='file'
                          accept='image/*'
                          hidden
                          {...props}
                          onChange={combinedOnchange}
                        />
                      </Button>
                      {error ? (
                        <FormHelperText error={true}>{error?.message}</FormHelperText>
                      ) : null}
                      {value ? <FormHelperText>{value[0]?.name}</FormHelperText> : null}
                      {uploadError ? (
                        <FormHelperText error={true}>{uploadError}</FormHelperText>
                      ) : null}
                    </>
                  )
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => {
                setOpen(false)
                setValue('banner', null)
              }}
              disabled={isSubmitting}
            >
              CANCEL
            </Button>
            <Button variant='contained' color='primary' type='submit' disabled={isSubmitting}>
              UPLOAD
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default EditPrizeBanner
