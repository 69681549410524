import TopNavbar from './LandingPage/TopNavbar'
import AboutUs from './LandingPage/AboutUs'
import Brands from './LandingPage/Brands'
import Contact from './LandingPage/Contact'
import Footer from './LandingPage/Footer'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Gothic',
    h1: {
      fontSize: '30px',
      fontWeight: '500',
      fontFamily: 'Gothic Bold',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: '300',
    },
    h3: {
      fontSize: '1.15rem',
      fontWeight: '200',
      color: '#767676',
    },
    h4: {
      fontSize: '14px',
      fontWeight: '200',
      color: '#767676',
    },
    h5: {
      fontSize: '18px',
      fontWeight: '300',
      color: '#5A5A5A',
    },
    subtitle1: {
      fontSize: '22px',
      fontWeight: '300',
      fontFamily: "'Corsica MX Medium Cond',sans-serif",
    },
    subtitle2: {
      fontSize: '8px',
      fontWeight: '500',
      fontFamily: "'Corsica MX Medium Cond',sans-serif",
    },
    button: {
      fontWeight: '1000',
    },
  },
})

const LandingPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <TopNavbar />
      <AboutUs />
      <Brands />
      <Contact />
      <Footer />
    </ThemeProvider>
  )
}

export default LandingPage
