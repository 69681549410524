import React from 'react'
import { Box, Typography } from '@mui/material'

const divStyle = {
  backgroundImage: 'url("/assets/AboutUsBackground.png")',
  backgroundSize: 'cover',
  height: '800px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  overflowX: 'hidden',
  overflowY: 'hidden',
}

export default function AboutUs() {
  return (
    <div id='aboutus' style={divStyle}>
      <Box
        height='100%'
        width='100%'
        flexDirection='column'
        pl={{ xs: 8, sm: 10, lg: 15 }}
        pt={{ xs: 15, sm: 25, md: 30, lg: 25 }}
      >
        <Box maxWidth={{ xs: 210, sm: 320, md: 500 }} textAlign='left'>
          <Typography
            sx={{
              fontSize: { xs: '23px', sm: '35px', lg: '62px' },
              color: '#fff',
              fontFamily: 'Corsica LX Bold',
              lineHeight: 1,
            }}
          >
            ABOUT US
          </Typography>
          <hr
            style={{
              width: '75%',
              height: '4px',
              backgroundColor: '#fff',
              border: 'none',
              margin: '8px 0',
            }}
          />
          <br />
          <Typography
            sx={{
              fontSize: { sm: '13px', md: '18px', lg: '20px' },
              color: '#fff',
            }}
          >
            HT Eco Battery Sdn Bhd was started in 2016, as part of HT Group’s expansion into the
            automotive battery business. Automotive battery distribution provides much synergy to
            our supply chain and marketing solutions to our customers.
          </Typography>
        </Box>
      </Box>
    </div>
  )
}
