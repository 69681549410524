import { useQuery, useMutation } from 'react-query'
import axios from 'axios'

const ENDPOINT = '/api/closing-date'
const QUERY_KEY = 'closing-date'

export const useGetClosingDate = (query = {}, config = {}) =>
  useQuery([QUERY_KEY, 'list', query], async () => (await axios.get(ENDPOINT)).data, config)

export const usePostClosingDate = (config = {}) =>
  useMutation((newData) => axios.post(ENDPOINT, newData), config)

export const usePatchClosingDate = (config = {}) =>
  useMutation((updatedData) => axios.patch(`${ENDPOINT}`, updatedData), config)

export const useDeleteClosingDate = (config = {}) =>
  useMutation((id) => axios.delete(`${ENDPOINT}`, { data: id }), config)
