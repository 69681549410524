import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { luckyDrawBanner, luckyDrawNextBanner } from '../constant/image'
import { ImageDialog } from './ImageDialog'

export const PrizeBanner = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Typography>
        Click{' '}
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
        >
          here
        </a>{' '}
        to see next month's prizes
      </Typography>
      <ImageDialog open={open} setOpen={setOpen} imgSrc={luckyDrawNextBanner} />
    </>
  )
}
