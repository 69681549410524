import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  TextField,
  TableHead,
  Select,
  MenuItem,
  Skeleton,
  CircularProgress,
} from '@mui/material'
import { useGetWinner, usePostWinner, useExportWinner } from '../../api/winner'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import WinnerPicker from './WinnerPicker'
import DownloadIcon from '@mui/icons-material/Download'

const SkeletonRow = () => {
  return (
    <TableRow>
      <TableCell sx={{ border: '1px solid #000' }}>
        <Skeleton animation='wave' />
      </TableCell>
      <TableCell sx={{ border: '1px solid #000' }}>
        <Skeleton animation='wave' />
      </TableCell>
      <TableCell sx={{ border: '1px solid #000' }}>
        <Skeleton animation='wave' />
      </TableCell>
    </TableRow>
  )
}

const WinnerList = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const startYear = 2024

  const [monthYearSelected, setMonthYearSelected] = useState(`${currentMonth}/${currentYear}`)

  const yearRange = []
  for (let year = currentYear; year >= startYear; year--) {
    const endMonth = year === currentYear ? currentMonth : 12
    for (let month = endMonth; month >= 1; month--) {
      yearRange.push({
        value: `${month}/${year}`,
        label: moment(`${(month + 1) % 12}/${year}`, 'M/YYYY').format('MMMM YYYY'),
      })
    }
  }

  const exportWinner = useExportWinner()

  const queryPeriod = monthYearSelected.split('/')

  const { data, isLoading, refetch } = useGetWinner({ month: queryPeriod[0], year: queryPeriod[1] })

  const [winners, setWinners] = useState([])

  const { mutateAsync: patch, isLoading: isLoadingPatch } = usePostWinner()
  const { enqueueSnackbar } = useSnackbar()

  const handleAddColumn = () => {
    const newRow = { id: winners.length + 1, prize: '', name: '' }
    setWinners([...winners, newRow])
  }

  const handleDeleteRow = () => {
    if (winners.length > 1) {
      setWinners(winners.slice(0, -1))
    }
  }

  const handleChange = (e, id, field) => {
    const updateWinners = winners.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: e.target.value }
      }
      return row
    })
    setWinners(updateWinners)
  }

  const handleSave = async () => {
    try {
      await patch({ winners, month: queryPeriod[0], year: queryPeriod[1] })
      enqueueSnackbar(`Update success`, {
        variant: 'success',
      })
      refetch()
    } catch (error) {
      enqueueSnackbar('Update fail', {
        variant: 'error',
      })
    }
  }

  const handleExportWinner = async () => {
    const res = await exportWinner
      .mutateAsync({ month: queryPeriod[0], year: queryPeriod[1] })
      .catch((e) => {
        enqueueSnackbar('Failed to export winner', {
          variant: 'error',
        })
      })

    if (res?.status === 200) {
      const url = URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `WinnerList.csv`)
      document.body.appendChild(link)
      link.click()
      enqueueSnackbar(`Winner List exported`, {
        variant: 'success',
      })
    }
  }

  useEffect(() => {
    if (data) {
      setWinners(data.winners)
    } else {
      setWinners([])
    }
  }, [data])

  return (
    <>
      <Box display='flex' flexDirection='column' alignItems='center' marginTop={2}>
        <Select
          value={monthYearSelected}
          onChange={(e) => setMonthYearSelected(e.target.value)}
          variant='outlined'
          fullWidth
        >
          {yearRange.map((item, i) => (
            <MenuItem value={item.value} key={i}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Box display='flex' justifyContent='flex-end' width='100%' mb={2}>
          <Button
            startIcon={<DownloadIcon />}
            endIcon={exportWinner.isLoading && <CircularProgress size='1rem' />}
            onClick={handleExportWinner}
            disabled={exportWinner.isLoading}
          >
            Export Winner List
          </Button>
        </Box>
        <Table size='small' aria-label='a dense table' sx={{ marginTop: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: '1px solid #000' }}>Id</TableCell>
              <TableCell sx={{ border: '1px solid #000' }}>Prize</TableCell>
              <TableCell sx={{ border: '1px solid #000' }}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
              </>
            ) : (
              winners.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ border: '1px solid #000' }}>{row.id}</TableCell>
                  <TableCell sx={{ border: '1px solid #000' }}>
                    <TextField
                      variant='standard'
                      value={row.prize}
                      sx={{ width: '100%' }}
                      onChange={(e) => handleChange(e, row.id, 'prize')}
                    />
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #000' }}>
                    <TextField
                      variant='standard'
                      value={row.name}
                      onChange={(e) => handleChange(e, row.id, 'name')}
                      sx={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <Box display='flex' justifyContent='center' marginTop={2}>
          <Button
            variant='contained'
            onClick={handleAddColumn}
            sx={{ marginRight: 2 }}
            disabled={isLoadingPatch || isLoading}
          >
            Add Row
          </Button>
          <Button
            variant='contained'
            onClick={handleDeleteRow}
            sx={{ marginRight: 2 }}
            disabled={isLoading}
          >
            Remove Row
          </Button>
          <Button
            variant='contained'
            onClick={handleSave}
            disabled={isLoadingPatch || isLoading}
            startIcon={
              isLoadingPatch && <CircularProgress size='1.5rem' style={{ color: 'white' }} />
            }
            sx={{ marginRight: 2 }}
          >
            SAVE
          </Button>
        </Box>
        <WinnerPicker queryPeriod={queryPeriod} />
      </Box>
    </>
  )
}

export default WinnerList
