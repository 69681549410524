import React from 'react'
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'

export const TermsCondition = () => {
  return (
    <Box
      minHeight={350}
      maxWidth={900}
      px={3}
      py={5}
      display='flex'
      justifyContent='center'
      alignContent='center'
      m='auto'
      flexDirection='column'
    >
      <Stepper orientation='vertical'>
        <Step active={true}>
          <StepLabel>Step 1</StepLabel>
          <StepContent>
            <Typography variant='h3'>
              <a href='/luckydraw/sign-up'>Sign up</a> for an account to check which category you
              are in.
            </Typography>
            <Box display='flex' justifyContent='center' marginTop={2}>
              <Table sx={{ maxWidth: 400 }} size='small' aria-label='a dense table'>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: '1px solid #000', background: '#3D6F00' }}>
                      Client Category
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #000', background: '#3D6F00' }}>
                      Lucky Draw Ticket
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: '1px solid #000' }}>POS Dealer</TableCell>
                    <TableCell sx={{ border: '1px solid #000' }}>
                      Five (5) batteries per ticket
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: '1px solid #000' }}>Redistribution Partner</TableCell>
                    <TableCell sx={{ border: '1px solid #000' }}>
                      Twenty (20) batteries per ticket
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </StepContent>
        </Step>
        <Step active={true}>
          <StepLabel>Step 2</StepLabel>
          <StepContent>
            <Typography variant='h3'>
              Purchase any Energizer or VARTA® automotive batteries with HT Eco Battery Sdn Bhd’s
              battery serial number from 1st May 2024. By participating this campaign, you confirm
              that you have read, understood, and will respect the terms and conditions & PDPA.
            </Typography>
          </StepContent>
        </Step>
        <Step active={true}>
          <StepLabel>Step 3</StepLabel>
          <StepContent>
            <Typography variant='h3'>
              <a href='/luckydraw/sign-in'>Log in</a> to your account to check the number of tickets
              to participate in the HT Monthly Fortune Finder Campaign anytime.
            </Typography>
          </StepContent>
        </Step>
        <Step active={true}>
          <StepLabel>Step 4</StepLabel>
          <StepContent>
            <Typography variant='h3'>
              You will receive an SMS stating the number of tickets you have collected in the month
              on the HT Eco Battery Sdn Bhd’s cutoff date.
            </Typography>
          </StepContent>
        </Step>
        <Step active={true}>
          <StepLabel>Step 5</StepLabel>
          <StepContent>
            <Typography variant='h3'>
              The HT Monthly Fortune Finder Campaign winners will be announced by the 10th of each
              month.
            </Typography>
          </StepContent>
        </Step>
      </Stepper>
      <Box mt={3}>
        <Typography variant='h4'>
          *Each Eligible Client will be entitled to a maximum of ten (10) lucky draw tickets per
          month.
        </Typography>
        <Typography variant='h4'>
          *To participate in the Campaign, Eligible Clients need to purchase Energizer and VARTA®
          batteries with HT Eco Battery Sdn Bhd’s battery serial number. If the serial number is not
          found in HT Eco Battery Sdn Bhd’s system, it will be considered ineligible for the
          participation in the HT Monthly Fortune Finder Campaign.
        </Typography>
        <Typography variant='h4'>
          *I have read and agree with the{' '}
          <a
            style={{ color: '#002984' }}
            href={'/assets/luckydraw/TermsAndConditions.pdf'}
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a
            style={{ color: '#002984' }}
            href={'/assets/luckydraw/PrivacyPolicy.pdf'}
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy.
          </a>
        </Typography>
      </Box>
    </Box>
  )
}
