import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useMe } from '../api/user'
import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'
import { luckyDrawBanner } from '../constant/image'
import Navbar from '../shared/Navbar'
import { Box } from '@mui/material'

const LuckyDrawPrivateRoute = () => {
  const { data, isLoading } = useMe({})

  const location = useLocation()
  const pathname = location.pathname
  const showBanner = pathname.substring(pathname.lastIndexOf('/') + 1) === 'ticket'

  if (isLoading) return <></>

  if (!data) return <Navigate to='/luckydraw/sign-in' />

  if (data.role === 'admin') return <Navigate to='/admin/panel' />

  return (
    <>
      {showBanner && (
        <Box display='flex' justifyContent='center' marginTop={7}>
          <img src={luckyDrawBanner} width='100%' />
        </Box>
      )}
      <Container component='main'>
        <Navbar user={data} />
        <Outlet />
      </Container>
    </>
  )
}

export default LuckyDrawPrivateRoute
