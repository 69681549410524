import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Select,
  MenuItem,
} from '@mui/material'
import { PrizeBanner } from './PrizeBanner'
import { IndirectDealerForm } from './IndirectDealerForm'
import { useGetPoint } from '../api/point'
import moment from 'moment'
import { range } from 'lodash'

const TicketAmount = ({ desc, total, length = 1 }) => {
  return (
    <Grid item xs={12} sm={12 / length}>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          height: '180px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'red',
            borderRadius: '40px',
            width: '300px',
          }}
        >
          <Button
            variant='contained'
            sx={{
              borderRadius: '30px',
              height: '90px',
              pointerEvents: 'none',
            }}
            fullWidth
          >
            {desc}
          </Button>
          <Box alignItems='center'>
            <Typography color='white' fontWeight='bold' fontSize='50px'>
              {total}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

const TicketInfo = () => {
  const today = moment().date()

  const currentDate = moment().subtract(today > 10 ? 0 : 1, 'months')
  const currentYear = currentDate.year()
  const currentMonth = currentDate.month() + 1
  const [monthYearSelected, setMonthYearSelected] = React.useState(`${currentMonth}/${currentYear}`)
  const queryPeriod = monthYearSelected.split('/')
  const diff = currentDate.diff(moment('2024-05-01'), 'months') + 1

  const yearRange = range(0, diff).map((i) => {
    const date = new Date(currentYear, currentMonth - i - 1, 1)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return {
      value: `${month}/${year}`,
      label: moment(`${(month + 1) % 12}/${year}`, 'M/YYYY').format('MMMM YYYY'),
    }
  })

  const { data: pointData = [], refetch } = useGetPoint({
    month: queryPeriod[0],
    year: queryPeriod[1],
  })

  const [open, setOpen] = React.useState(false)
  const pointDistribution = pointData.isReseller
    ? [
        {
          desc: 'Redistribution Partner’s Number of Tickets',
          total: pointData.resellerTicket ?? 0,
        },
        {
          desc: 'Allocatable Number of Tickets',
          total: pointData.indirectTicket ?? 0,
        },
      ]
    : [
        {
          desc: 'Total Number of Tickets',
          total: pointData.dealerTicket ?? 0,
        },
      ]

  return (
    <>
      <Table
        size='small'
        aria-label='a dense table'
        sx={{ minWidth: 350, maxWidth: 800, marginTop: 5 }}
      >
        <TableBody>
          <TableRow>
            <TableCell style={{ border: 'none', fontSize: '1rem' }}>Business Name</TableCell>
            <TableCell style={{ border: 'none', fontSize: '1rem', fontWeight: 'bold' }}>
              {pointData.companyName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ border: 'none', fontSize: '1rem' }}>Client Category</TableCell>
            <TableCell style={{ border: 'none', fontSize: '1rem', fontWeight: 'bold' }}>
              {pointData.isReseller ? 'Redistribution Partner' : 'POS Dealer'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ border: 'none', fontSize: '1rem' }}>Eligibility Criteria</TableCell>
            <TableCell style={{ border: 'none', fontSize: '1rem', fontWeight: 'bold' }}>
              {pointData.isReseller ? 20 : 5} Batteries Per Ticket
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container marginTop={5}>
        <Grid item xs={12} md={6}>
          <Table size='small' aria-label='a dense table'>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <Button
                    variant='contained'
                    sx={{
                      borderRadius: '50px',
                      pointerEvents: 'none',
                    }}
                  >
                    Number of Purchase
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none', fontSize: '1rem' }}>Purchase period</TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  <Select
                    value={monthYearSelected}
                    onChange={(e) => setMonthYearSelected(e.target.value)}
                    size='small'
                  >
                    {yearRange.map((item, i) => (
                      <MenuItem value={item.value} key={i}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: 'none', fontSize: '1rem' }}>
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{
                      borderRadius: '50px',
                      pointerEvents: 'none',
                      backgroundColor: '#D22630',
                    }}
                  >
                    Energizer
                  </Button>
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {pointData.ENG ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: 'none', fontSize: '1rem' }}>
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{
                      borderRadius: '50px',
                      pointerEvents: 'none',
                      backgroundColor: '#00449E',
                    }}
                  >
                    Varta
                  </Button>
                </TableCell>
                <TableCell
                  style={{
                    border: 'none',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {pointData.VTA ?? 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} md={6} marginTop={{ xs: 5, md: 0 }} textAlign='center'>
          <Grid container spacing={3}>
            {pointDistribution.map((v, i) => (
              <TicketAmount
                key={i}
                total={v.total}
                desc={v.desc}
                length={pointDistribution.length}
              />
            ))}
          </Grid>
          {monthYearSelected === `${currentMonth}/${currentYear}` &&
            pointData.isReseller &&
            pointData.indirectTicket > 0 &&
            (today <= 3 || today > 10) &&
            !open && (
              <Button
                variant='contained'
                sx={{
                  borderRadius: '50px',
                  marginTop: 5,
                }}
                onClick={() => setOpen(true)}
              >
                Submit Indirect POS Details
              </Button>
            )}
        </Grid>
      </Grid>
      {open && (
        <Box marginTop={5}>
          <IndirectDealerForm
            setOpen={setOpen}
            refetch={refetch}
            maxTicket={pointData.indirectTicket ?? 0}
          />
        </Box>
      )}
      <PrizeBanner />
      <Typography>
        To access the winner list of the previous month, you may click{' '}
        <a href='/luckydraw/winners'>here</a>
      </Typography>
      <Typography variant='h4' marginBottom={5}>
        By participating this campaign, you agree to our{' '}
        <a
          href={'/assets/luckydraw/TermsAndConditions.pdf'}
          target='_blank'
          rel='noopener noreferrer'
        >
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href={'/assets/luckydraw/PrivacyPolicy.pdf'} target='_blank' rel='noopener noreferrer'>
          Privacy Policy.
        </a>
      </Typography>
    </>
  )
}

export default TicketInfo
