import React from 'react'
import { Box, Dialog, DialogTitle, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export const DisplayImageDialog = (props) => {
  const { handleClose, open, uploadPath } = props
  return (
    <Dialog onClose={handleClose} open={open}>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        m={1}
      >
        <DialogTitle>Image</DialogTitle>
        <Button startIcon={<CloseIcon />} onClick={handleClose}>
          close
        </Button>
      </Box>

      <Box display='flex' justifyContent='center' m={3} flexDirection='column'>
        <img src={`${uploadPath}`} height='auto' width='100%' />
        <Button
          color='primary'
          href={`${uploadPath}`}
          target='_blank'
          rel='noopener noreferrer'
          sx={{ textTransform: 'none', textDecoration: 'underline' }}
        >
          Not showing? Click here.
        </Button>
      </Box>
    </Dialog>
  )
}
