import React from 'react'
import Box from '@mui/material/Box'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Skeleton,
  Typography,
  Button,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useGetWinner } from '../api/winner'
import moment from 'moment'

const SkeletonRow = () => {
  return (
    <TableRow>
      <TableCell sx={{ border: '1px solid #000' }}>
        <Skeleton animation='wave' />
      </TableCell>
      <TableCell sx={{ border: '1px solid #000' }}>
        <Skeleton animation='wave' />
      </TableCell>
      <TableCell sx={{ border: '1px solid #000' }}>
        <Skeleton animation='wave' />
      </TableCell>
    </TableRow>
  )
}

const WinnerList = () => {
  const { data, isLoading } = useGetWinner()

  return (
    <>
      <Box
        sx={{
          marginTop: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h1'
          fontSize={{ xs: '2.5rem', sm: '3rem' }}
          fontWeight={'bold'}
          sx={{
            whiteSpace: 'pre-line',
            '@media (max-width: 600px)': {
              '& span:last-child': {
                display: 'block',
                marginTop: '8px',
                textAlign: 'center',
              },
            },
          }}
        >
          <span>Winner List </span>
          <span>
            {data
              ? moment(`${(data?.month + 1) % 12}/${data?.year}`, 'M/YYYY').format('MMMM YYYY')
              : ''}
          </span>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
        <Button variant='contained' component={Link} to='/luckydraw/ticket'>
          Back
        </Button>
      </Box>
      {isLoading ? (
        <Table size='small' aria-label='a dense table' sx={{ marginTop: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: '1px solid #000' }}>Id</TableCell>
              <TableCell sx={{ border: '1px solid #000' }}>Prize</TableCell>
              <TableCell sx={{ border: '1px solid #000' }}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
          </TableBody>
        </Table>
      ) : data?.winners?.length > 0 ? (
        <Table size='small' aria-label='a dense table' sx={{ marginTop: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: '1px solid #000' }}>Id</TableCell>
              <TableCell sx={{ border: '1px solid #000' }}>Prize</TableCell>
              <TableCell sx={{ border: '1px solid #000' }}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.winners?.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid #000' }}>{row.id}</TableCell>
                <TableCell sx={{ border: '1px solid #000' }}>{row.prize}</TableCell>
                <TableCell sx={{ border: '1px solid #000' }}>{row.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant='body1' align='center' sx={{ marginTop: 5 }}>
          No winners at the moment.
        </Typography>
      )}
      <Box marginBottom={10} />
    </>
  )
}

export default WinnerList
