import React from 'react'
import { Navigate } from 'react-router-dom'
import { useMe } from '../api/user'
export const LuckyDrawRedirectRoute = () => {
  const { data, isLoading } = useMe({})

  if (isLoading) return <></>

  if (!data) return <Navigate to='/luckydraw/sign-in' />

  return <Navigate to='/luckydraw/ticket' />
}

export default LuckyDrawRedirectRoute
