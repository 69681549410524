import React from 'react'
import Drawer from '@mui/material/Drawer'
import { Box, Button } from '@mui/material'
import { Link } from 'react-scroll'
import { Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Link as CrossLink } from 'react-router-dom'

const linkStyle = {
  color: 'black',
  textDecoration: 'none',
  margin: '0',
  fontFamily: 'Gothic',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5',
  cursor: 'pointer',
  transition: 'color 0.3s',
  padding: '15px 20px',
}

const activeStyle = {
  borderBottom: '2px solid #1d4ed8',
  color: '#1d4ed8',
}

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Drawer
      anchor='right'
      variant='temporary'
      open={sidebarOpen}
      onClose={() => toggleSidebar(!sidebarOpen)}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box flexDirection='column' display='flex' width={300}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            padding: '20px',
          }}
        >
          <CloseIcon onClick={() => toggleSidebar(!sidebarOpen)} />
        </Box>
        <Divider />
        <Link
          offset={-50}
          style={linkStyle}
          to='aboutus'
          smooth={true}
          activeStyle={activeStyle}
          spy={true}
          onClick={() => toggleSidebar(!sidebarOpen)}
        >
          About Us
        </Link>
        <Divider />
        <Link
          offset={-30}
          style={linkStyle}
          to='brands'
          smooth={true}
          activeStyle={activeStyle}
          spy={true}
          onClick={() => toggleSidebar(!sidebarOpen)}
        >
          Brands
        </Link>
        <Divider />
        <Link
          offset={-30}
          style={linkStyle}
          to='contactus'
          smooth={true}
          activeStyle={activeStyle}
          spy={true}
          onClick={() => toggleSidebar(!sidebarOpen)}
        >
          Contact Us
        </Link>
        <Divider />
        <Button
          color='primary'
          variant='contained'
          component={CrossLink}
          to='/promotions'
          style={{ backgroundColor: 'red', borderRadius: 0, margin: 20 }}
        >
          PROMOTIONS
        </Button>
      </Box>
    </Drawer>
  )
}
